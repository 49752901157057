<template>
  <section>
    <b-card bg-variant="light">
      <span v-if="showAdvanced">
        <b>Advanced</b>
        <b-button
          size="sm"
          variant="flat-primary"
          @click="showAdvanced = !showAdvanced"
        >
          <feather-icon v-if="showAdvanced" icon="ChevronDownIcon" size="12"/>
          <feather-icon v-else icon="ChevronUpIcon" size="12"/>
        </b-button>
        <b-card>
          <b-row>
            <b-col cols="2"><small><b>ISSN</b></small></b-col>  
            <b-col cols="2"><small><b>Surname</b></small></b-col>
            <b-col cols="2"><nobr><small><b>Given Names</b></small></nobr></b-col>
            <b-col cols="4"><small><b>Affiliation</b></small></b-col>
            <b-col cols="2"><small><b><nobr>Page Size</nobr></b></small></b-col>
          </b-row>
          <b-row>
            <b-col cols="2"> <b-form-input v-model="searchParams.searchOnIssn" size="sm"/> </b-col>
            <b-col cols="2"><b-form-input v-model="searchParams.surname" size="sm"/></b-col>
            <b-col cols="2"><b-form-input v-model="searchParams.given_name" size="sm"/></b-col>
            <b-col cols="4"><b-form-input v-model="searchParams.affiliation" size="sm" /></b-col>
            <b-col cols="2">
              <b-form-select v-model="resultsPerPage" :options="resultsPerPageOptions" size="sm"/>  
            </b-col>
          </b-row>
        
          <br>
          <b-row>
            <b-col cols="1">
              <b-button 
                v-ripple.400="'rgba(255, 255, 255, 0.15)'" 
                size="sm" variant="primary"  
                @click.stop="performSearch()"
              >
                Search
              </b-button>
            </b-col>
            <b-col cols="1">
              <b-button 
                v-ripple.400="'rgba(255, 255, 255, 0.15)'" 
                size="sm" variant="primary"  
                @click.stop="initSearchParams()"
              >
                Clear
              </b-button>
            </b-col>
            <b-col cols="3">
              <b-button 
                v-ripple.400="'rgba(255, 255, 255, 0.15)'" 
                size="sm" variant="primary"  
                @click.stop="exportSearcnResults()"
              >
                Export Results (ISSN:{{ $route.params.issn }})
              </b-button>
            </b-col>

            <b-col v-if="totalResults" cols="10" align="right">
              <span v-if="totalResults && totalResults.value < 10000"><b>Total: </b>{{ totalResults.value | formatNumber }}</span>
              <span v-else><b>Total: +</b>{{ totalResults.value | formatNumber }}</span>
              <b-pagination
                v-model="currentPage"
                :total-rows="totalResults.value"
                :per-page="resultsPerPage"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                align="right"
              />
            </b-col>

          </b-row>
        </b-card>
      </span>
      <span v-else>
        <b-row>
          <b-col cols="4">
            <b>Advanced</b>
            <b-button
              size="sm"
              variant="flat-primary"
              @click="showAdvanced = !showAdvanced"
            >
              <feather-icon v-if="showAdvanced" icon="ChevronDownIcon" size="12"/>
              <feather-icon v-else icon="ChevronUpIcon" size="12"/>
            </b-button>
          </b-col>
          <b-col cols="1">
            <b-button 
              v-ripple.400="'rgba(255, 255, 255, 0.15)'" 
              size="sm" variant="primary"  
              @click.stop="initSearchParams()"
            >
              Clear
            </b-button>
          </b-col>
          <b-col cols="3">
            <b-button 
              v-ripple.400="'rgba(255, 255, 255, 0.15)'" 
              size="sm" variant="primary"  
              @click.stop="exportSearcnResults()"
            >
              Export Results (ISSN:{{ $route.params.issn }})
            </b-button>
          </b-col>

          <b-col v-if="totalResults" cols="4" align="right">
            <span v-if="totalResults && totalResults.value < 10000"><b>Total: </b>{{ totalResults.value | formatNumber }}</span>
            <span v-else><b>Total: +</b>{{ totalResults.value | formatNumber }}</span>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalResults.value"
              :per-page="resultsPerPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              align="right"
            />
          </b-col>
        </b-row>

      </span>
      
      <div v-if="reportResultsError">
        <br>
        <strong class="text-danger">{{ reportResultsError }}</strong>
      </div>
      <div v-if="reportResults && reportResults.length == 0">
        <br>
        <strong class="text-warning">Results not found for this query</strong>
      </div>

      <div v-if="reportResults && reportResults.length > 0">
        <b-row> 
          <b-col v-if="aggregations" cols="4">
            <b-card>
              <b-card-actions v-if="aggregations.issn && aggregations.issn.buckets.length > 1" bg-variant="light" title="ISSN" action-collapse>
                <span v-for="(bucket, index) in aggregations.issn.buckets" :key="index">
                  <b-row>
                    <b-col cols="9">
                      <a class="text-primary" @click="searchOn('searchOnIssn', bucket.key)"> {{ bucket.key }} - {{ publications[bucket.key] }} </a>  
                    </b-col>
                    <b-col cols="3"><nobr>({{ bucket.doc_count }})</nobr></b-col>
                  </b-row>
                </span>
              </b-card-actions>
            </b-card>
          </b-col>
          <b-col :cols="colsForResults">
            <div v-for="(item, index) in reportResults" :key="index">
              <b-card>
                <br>
                <b-row>
                  <b-col cols="2"><b>Author</b></b-col>
                  <b-col cols="8">{{ item.given_name }} &nbsp; {{ item.surname }}</b-col>
                  <b-col cols="2">
                    <a class="text-primary" @click="searchOn('articlesForAuthor', item)">articles</a> 
                  </b-col>
                </b-row>
                <span v-if="item.affiliation">
                  <br/>
                  <b-row>
                    <b-col cols="2"><b>Affiliation</b> </b-col>
                    <b-col cols="8"><a class="text-primary" @click="searchOn('affiliation', item.affiliation)"> {{ item.affiliation }}</a> </b-col>
                    <b-col cols="2">
                      <a class="text-primary" @click="searchOn('articlesForAffiliation', item)">articles</a> 
                    </b-col>
                  </b-row>
                </span>
              </b-card>
              <br>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col v-if="aggregations" cols="4"></b-col>
          <b-col :cols="colsForResults">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalResults.value"
              :per-page="resultsPerPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              align="right"
            />
          </b-col>
        </b-row>
      </div>
    </b-card>
  </section>
</template> 
 
<script>

import publicationApi from '@/dl_pubmill/apis/publication'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'

import {
  BCard, BRow, BCol, BButton, BFormInput, BFormSelect, BPagination,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {

  components: {
    BCard,
    BCardActions,
    BRow,
    BCol,
    BButton,
    BFormInput,
    BFormSelect,
    BPagination,
  },
  directives: {
    Ripple,
  },
  props: {
    publications: {
      type: Object,
      default: null
    },
    authorsFilter: {
      type: Object,
      default: null
    },
  },
  data () {
    return {
      FORMAT: null,
      searchParams: {},
      totalResults: null,
      currentPage: null,
      resultsPerPage: null,
      exportResults: null,
      aggregations: null,
      showAdvanced: null,
      reportResults: null,
      reportResultsError: null,
    }
  },
  computed: {
    colsForResults () {
      if (this.aggregations) {
        return '8'
      } 
      return '12'  
    },
    resultsPerPageOptions () {
      return [25, 50, 100, 500]
    },
  },
  watch: {
    currentPage: {
      //watch on route changes it not only needed when same .vue is enteredfrom multiple nav points 
      handler (newVal, oldVal) {
        console.log('currentPage:', this.currentPage, 'newVal:', newVal, 'oldVal:', oldVal)
        if (oldVal != null) {
          this.searchQueryRequest()
        }
      }
    },
    resultsPerPage: {
      //watch on route changes it not only needed when same .vue is enteredfrom multiple nav points 
      handler (newVal, oldVal) {
        console.log('resultsPerPage:', this.resultsPerPage, 'newVal:', newVal, 'oldVal:', oldVal)
        if (oldVal != null) {
          this.currentPage = 1 //looks like this does not trigger the watch .. so calling query here
          this.searchQueryRequest()
        }
      }
    }   
  },
  mounted () { 
    console.log('4444444444 searchAuthors mounted')
    this.initSearchParams()
  },
  methods: {
    initSearchParams () {
      this.searchParams =  {}
      if (this.authorsFilter && this.authorsFilter.type === 'affiliation') {
        //console.log('aaaaa', this.articlesFilter)
        this.searchParams.affiliation = this.authorsFilter.value
        this.authorsFilter.type = null
      }
      this.resultsPerPage = 25
      this.currentPage = 1
      this.searchQueryRequest()
    },
    searchOn(field, value) {
      if (field === 'articlesForAuthor') {
        this.$emit('articlesForAuthor', value.given_name.concat(' ',  value.surname))
        this.$router.push({ name: 'search', params: { issn: this.$route.params.issn, tab: 0 } })
        return
      }
      if (field === 'articlesForAffiliation') {
        this.$emit('articlesForAffiliation', value.affiliation)
        this.$router.push({ name: 'search', params: { issn: this.$route.params.issn, tab: 0 } })
        return
      }
      if (field === 'searchOnIssn') {
        this.searchParams.searchOnIssn = value
        this.searchQueryRequest()
        if (this.$route.params.issn !== value || this.$route.params.tab !== 1) {
          //console.log('ffff', value, this.$route.params.issn, this.$route.params.tab)
          this.$router.push({ name: 'search', params: { issn: value, tab: 1 } })
        }
        return
      }
      if (field === 'affiliation') {
        this.searchParams.affiliation = value
      }
      this.searchQueryRequest()
    },
    exportSearcnResults () {
      this.exportResults = 'y'
      this.performSearch()
    },
    performSearch () {
      this.searchQueryRequest()
    },
    processSearchResults (serverData) {
      if (serverData.error) {
        console.log(serverData.error)
        this.reportResultsError = serverData.error
      } else {
        this.totalResults =  serverData.hits.total
        const pageResults =  serverData.hits.hits
        this.aggregations = serverData.aggregations

        console.log('reportResults', serverData)

        this.reportResults = []
        for (let i = 0; i < pageResults.length; i++) {
          const source = pageResults[i].source
          const highlight = pageResults[i].highlight
          if (highlight && highlight.text) {
            source.highlightedText = highlight.text
          }
          this.reportResults.push(source)

        }
      }
    },
    searchQueryRequest () {
      this.reportResults = null
      this.reportResultsError = null
 
      const requestObj = {
        issn: this.$route.params.issn,
      }

      requestObj.returnRows =  this.resultsPerPage
      if (this.currentPage > 1) {
        requestObj.startingRow =  this.resultsPerPage * (this.currentPage - 1)
      }
      if (this.searchParams.searchOnIssn && this.searchParams.searchOnIssn.trim().length > 0) {
        requestObj.searchOnIssn =   this.searchParams.searchOnIssn.trim()
      } 
      if (this.searchParams.surname && this.searchParams.surname.trim().length > 0) {
        requestObj.surname =   this.searchParams.surname.trim()
      } 
      if (this.searchParams.given_name && this.searchParams.given_name.trim().length > 0) {
        requestObj.given_name =   this.searchParams.given_name.trim()
      } 
      if (this.searchParams.affiliation && this.searchParams.affiliation.trim().length > 0) {
        requestObj.affiliation =   this.searchParams.affiliation.trim()
      } 
      //console.log('ssssss searchParams', this.searchParams)
      //console.log('ssssss requestObj', requestObj)
      requestObj.action =  'search_query_authors_request'

      if (this.exportResults === 'y') {
        requestObj.searchOnIssn =  requestObj.issn
        requestObj.returnRows =  10000
        requestObj.startingRow =  0
        requestObj.exportQuery =  'y' 
      }
      publicationApi.searchQueryRequest(requestObj, this, null)

    },
    backFromSearchQueryRequest (serverData) {
      if (this.exportResults === 'y') {
        this.exportResults = null
        this.$router.push({ name: 'reports', params: { issn: this.$route.params.issn } })
        return
      }
      this.processSearchResults(serverData)
    }
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
