<template>
  <section>
    <h3 class="content-header-title float-left pr-1 mb-0" style="margin-right:1rem;"> 
      Search
    </h3>
    <h5 style="padding-top:.3rem;"> 
      <router-link :to="homeRouterName()">Home</router-link>
    </h5>
    <b-tabs v-model="tabIndex" active-nav-item-class="text-primary">
      <b-tab lazy>
        <template #title>
          <span>Articles</span>
        </template>
        <br>
        <search-articles 
          v-if="publications" 
          :publications="publications"
          :articles-filter="articlesFilter"
        />
      </b-tab>
      <b-tab lazy>
        <template #title>
          <span>Authors</span>
        </template>
        <br>
        <search-authors 
          v-if="publications" 
          :publications="publications"
          :authors-filter="authorsFilter"
          @articlesForAuthor="applyArticleAuthorFiltering"
          @articlesForAffiliation="applyArticleAffiliationFiltering"
        />
      </b-tab>
      <b-tab lazy>
        <template #title>
          <span>Affiliations</span>
        </template>
        <br>
        <search-affiliations
          v-if="publications" 
          :publications="publications"
          @articlesForAffiliation="applyArticleAffiliationFiltering"
          @authorsForAffiliation="applyAuthorAffiliationFiltering"
        />
      </b-tab>
      <b-tab lazy>
        <template #title>
          <span>Funders</span>
        </template>
        <br>
        <search-funders
          v-if="publications" 
          :publications="publications"
          @articlesForFunder="applyArticleFunderFiltering"
        />
      </b-tab>
    </b-tabs> 

  </section>
</template> 
 
<script>

import publicationApi from '@/dl_pubmill/apis/publication'

import {
  BTabs, BTab,
} from 'bootstrap-vue'

import SearchArticles from './SearchArticles.vue'
import SearchAuthors from './SearchAuthors.vue'
import SearchAffiliations from './SearchAffiliations.vue'
import SearchFunders from './SearchFunders.vue'

export default {

  components: {
    BTabs, 
    BTab,
    SearchArticles,
    SearchAuthors,
    SearchAffiliations,
    SearchFunders,
  },
  
  data () {
    return {
      publications: null,
      tabIndex: 0,
      articlesFilter: null,
      authorsFilter: null,
    }
  },
  computed: {
  },  

  watch: {
    $route: {
      //watch on route changes it not only needed when same .vue is entered from multiple nav points 
      handler (newVal, oldVal) {
        console.log('1111 SearchMain route:', this.$route.name, 'newVal:', newVal.name, 'oldVal:', oldVal)
        if (this.$route.params.tab) {
          setTimeout(() => this.resetTab(this.$route.params.tab), 500) 
        } else {
          setTimeout(() => this.resetTab(0), 500) 
        }
      }
    },
    tabIndex: {
      handler (newVal, oldVal) {
        if (this.$route.params.tab !== newVal) {
          console.log('1111new tab index', this.$route.params.tab, 'newVal:', newVal, 'oldVal:', oldVal)
          this.$router.push({ name: 'search', params: { issn: this.$route.params.issn, tab: newVal } })
        }
      }
    },
  },
  created() {
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', true)
  },
  destroyed() {
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', false)
  },
  mounted () { 
    this.getMyPublications()
    if (this.$route.params.tab) {
      console.log('777777 this.$route.params.tab', this.$route.params.tab)
      setTimeout(() => this.resetTab(this.$route.params.tab), 500) 
    } 
  },
  methods: {
    applyArticleAuthorFiltering(author) {
      //console.log('afasfafaffass', author)
      this.articlesFilter = { type: 'author', value: author }
    },
    applyArticleAffiliationFiltering(affiliation) {
      this.articlesFilter = { type: 'affiliation', value: affiliation }
    },
    applyAuthorAffiliationFiltering(affiliation) {
      this.authorsFilter = { type: 'affiliation', value: affiliation }
    },
    applyArticleFunderFiltering(funding) {
      //console.log('afasfafaffass', author)
      this.articlesFilter = { type: 'funding', value: funding }
    },
    resetTab(tab) {
      console.log('66666666 setting new tabIndex', tab)
      this.tabIndex = parseInt(tab, 10)
    },
    homeRouterName () {
      return {
        name: 'production-admin',
        params: {
          issn: this.$route.params.issn,
        }
      }
    },
    getMyPublications () {
      publicationApi.getMyPublications(this, null)
    },
    backFromGetMyPublications (serverData) {
      //console.log('backFromGetMyPublications', serverData)
      this.publications = []
      if (serverData.error) {
        console.log(serverData.error)
      } else {
        //this.publications = serverData.administered_publications
        console.log('fffffff', serverData.administered_publications)
        this.publications = {}
        for (let i = 0; i < serverData.administered_publications.length; i++) {
          const rec = serverData.administered_publications[i]
          this.publications[rec.issn] = rec.short_name
        }
      }
    }
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
