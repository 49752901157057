<template>
  <section v-if="finishedLoadingTaxonomy || FORMAT == 'PMC'">
    <b-card bg-variant="light">
      <span v-if="showAdvanced">
        <b-button
          variant="flat-primary"
          @click="showAdvanced = !showAdvanced"
        >
          Advanced
          <feather-icon v-if="showAdvanced" icon="ChevronDownIcon" size="12"/>
          <feather-icon v-else icon="ChevronUpIcon" size="12"/>
        </b-button>
        <b-card>
          <b-row>
            <b-col cols="7"><small><b>Search phrase or term</b></small></b-col>
            <b-col cols="1"><nobr><small><b>In Title</b></small></nobr></b-col>
            <b-col cols="1"><nobr><small><b>Title or Text</b></small></nobr></b-col>
            <b-col cols="1"><nobr><small><b>In Figures</b></small></nobr></b-col>
            <b-col cols="1"><nobr><small><b>In Tables</b></small></nobr></b-col>
            <b-col cols="1"><nobr><small><b>In Suppl. Mat.</b></small></nobr></b-col>
          </b-row>
          <b-row>
            <b-col cols="7"><b-form-input v-model="searchParams.term" size="sm" /></b-col>
            <b-col cols="1"><b-form-checkbox v-model="searchParams.inTitle"/></b-col>
            <b-col cols="1"><b-form-checkbox v-model="searchParams.inTitleOrText"/></b-col>
            <b-col cols="1"><b-form-checkbox v-model="searchParams.inFigures"/></b-col>
            <b-col cols="1"><b-form-checkbox v-model="searchParams.inTables"/></b-col>
            <b-col cols="1"><b-form-checkbox v-model="searchParams.inSupplMat"/></b-col>
          </b-row>
          <br/>
          <b-row>
            <b-col cols="3"><small><b>DOI</b></small></b-col>
            <b-col cols="2"><small><b>ISSN</b></small></b-col>  
            <b-col cols="1"><small><b>Vol</b></small></b-col>
            <b-col cols="2"><small><b>Issue</b></small></b-col>
            <b-col cols="1"><nobr><small><b>From Year</b></small></nobr></b-col>
            <b-col cols="1"><nobr><small><b>To Year</b></small></nobr></b-col>
            <b-col cols="1"><nobr><small><b>EPub Dates</b></small></nobr></b-col>
          </b-row>
          <b-row>
            <b-col cols="3"><b-form-input v-model="doi" size="sm"/></b-col>
            <b-col cols="2"> <b-form-input v-model="searchParams.searchOnIssn" size="sm"/> </b-col>
            <b-col cols="1"> <b-form-input v-model="searchParams.volume" size="sm"/> </b-col>
            <b-col cols="2"> <b-form-input v-model="searchParams.issue" size="sm"/></b-col>
            <b-col cols="1"> <b-form-input v-model="searchParams.from_year" size="sm"/></b-col>
            <b-col cols="1"> <b-form-input v-model="searchParams.to_year"  size="sm" /></b-col>
            <b-col cols="1"><b-form-checkbox v-model="searchParams.epub"/></b-col>
          </b-row>
      
          <br/>
          <b-row>
            <b-col cols="8"><small><b>Type Author and press enter</b></small></b-col>  
            <b-col cols="4"><nobr><small><b>Corresponding Authors only</b></small></nobr></b-col>
          </b-row>
          <b-row>
            <b-col cols="8"> <b-form-tags v-model="searchParams.author" placeholder="Add Author"  class="mb-2"/> </b-col>
            <b-col cols="1"><b-form-checkbox v-model="searchParams.correspOnly"/></b-col>
          </b-row>
          <b-row>
            <b-col cols="6"><small><b>Author Affiliation</b></small></b-col>  
            <b-col cols="6"><small><b>Funding</b></small></b-col>  
          </b-row>
          <b-row>
            <b-col cols="6"> <b-form-input v-model="searchParams.affiliation" size="sm"/> </b-col>
            <b-col cols="6"> <b-form-input v-model="searchParams.funding" size="sm"/> </b-col>
          </b-row>
          <br/>
          <b-row>
            <b-col cols="4"><small><b>License</b></small></b-col>
            <b-col cols="1"><nobr><small><b>Has Tables</b></small></nobr></b-col>
            <b-col cols="1"><nobr><small><b>Has MathML</b></small></nobr></b-col>
            <b-col cols="1"><nobr><small><b>Has Latex</b></small></nobr></b-col>
          </b-row>
          <b-row>
            <b-col cols="4"> <b-form-select v-model="searchParams.license" :options="licenses" size="sm"/>  </b-col>
            <b-col cols="1"><b-form-checkbox v-model="searchParams.hasTables"/></b-col>
            <b-col cols="1"><b-form-checkbox v-model="searchParams.hasMathML"/></b-col>
            <b-col cols="1"><b-form-checkbox v-model="searchParams.hasLatex"/></b-col>
          </b-row>
          <br/>
          <b-row>
            <b-col cols="2"><small><b>Article Type (jats)</b></small></b-col>  
            <b-col cols="8">
              <a v-if="FORMAT == 'literatum'" class="text-primary" @click="promptToSelectNewTaxonomy()"><small>Categories</small></a>
              <span v-else>
                <small>Categories</small>
                <a v-if="searchParams.pmcArticleCategories" class="text-primary" @click="initPmcArticleCategoriesSearch()"> (clear) </a>
              </span>
            </b-col>
            <b-col cols="2"><small><b><nobr>Page Size</nobr></b></small></b-col>
          </b-row>
          <b-row>
            <b-col cols="2"> <b-form-select v-model="searchParams.article_type" :options="articleTypes" size="sm"/>  </b-col>
            <b-col cols="8"> 
              <b-card v-if="FORMAT == 'literatum'" bg-variant="light">
                <b-row v-for="(category, index) in searchParams.articleCategories" :key="index"> 
                  <b-col cols="12"> {{ pmcSearchCategoryDisplay }} </b-col>
                </b-row> 
              </b-card>
              <b-card v-if="FORMAT == 'PMC'" bg-variant="light">
                <b-row> 
                  <b-col cols="12"> {{ pmcSearchCategoryDisplay }} </b-col>
                </b-row> 
              </b-card>
            </b-col>
            <b-col cols="2">
              <b-form-select v-model="resultsPerPage" :options="resultsPerPageOptions" size="sm"/>  
            </b-col>
          </b-row>

          <br>
          <b-row>
            <b-col cols="1">
              <b-button 
                v-ripple.400="'rgba(255, 255, 255, 0.15)'" 
                size="sm" variant="primary"  
                @click.stop="performSearch()"
              >
                Search
              </b-button>
            </b-col>
            <b-col cols="1">
              <b-button 
                v-ripple.400="'rgba(255, 255, 255, 0.15)'" 
                size="sm" variant="primary"  
                @click.stop="initSearchParams()"
              >
                Clear
              </b-button>
            </b-col>
            <b-col cols="3">
              <b-button 
                v-ripple.400="'rgba(255, 255, 255, 0.15)'" 
                size="sm" variant="primary"  
                @click.stop="exportSearcnResults()"
              >
                Export Results (ISSN:{{ $route.params.issn }})
              </b-button>
            </b-col>

            <b-col v-if="totalResults" cols="7" align="right">
              <span v-if="totalResults && totalResults.value < 10000"><b>Total: </b>{{ totalResults.value | formatNumber }}</span>
              <span v-else><b>Total: +</b>{{ totalResults.value | formatNumber }}</span>
              <b-pagination
                v-model="currentPage"
                :total-rows="totalResults.value"
                :per-page="resultsPerPage"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                align="right"
              />
            </b-col>

          </b-row>
        </b-card>
      </span>
      <span v-else>
        <b-row>
          <b-col cols="4">
            <b-button
              variant="flat-primary"
              @click="showAdvanced = !showAdvanced"
            >  
              Advanced
              <feather-icon v-if="showAdvanced" icon="ChevronDownIcon" size="12"/>
              <feather-icon v-else icon="ChevronUpIcon" size="12"/>
            </b-button>
          </b-col>
          <b-col cols="1">
            <b-button 
              v-ripple.400="'rgba(255, 255, 255, 0.15)'" 
              size="sm" variant="primary"  
              @click.stop="initSearchParams()"
            >
              Clear
            </b-button>
          </b-col>
          <b-col cols="3">
            <b-button 
              v-ripple.400="'rgba(255, 255, 255, 0.15)'" 
              size="sm" variant="primary"  
              @click.stop="exportSearcnResults()"
            >
              Export Results (ISSN:{{ $route.params.issn }})
            </b-button>
          </b-col>

          <b-col v-if="totalResults" cols="4" align="right">
            <span v-if="totalResults && totalResults.value < 10000"><b>Total: </b>{{ totalResults.value | formatNumber }}</span>
            <span v-else><b>Total: +</b>{{ totalResults.value | formatNumber }}</span>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalResults.value"
              :per-page="resultsPerPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              align="right"
            />
          </b-col>
        </b-row>

      </span>
      
      <div v-if="reportResultsError">
        <br>
        <strong class="text-danger">{{ reportResultsError }}</strong>
      </div>
      <div v-if="reportResults && reportResults.length == 0">
        <br>
        <strong class="text-warning">Results not found for this query</strong>
      </div>

      <div v-if="reportResults && reportResults.length > 0">
        <b-row> 
          <b-col v-if="aggregations" cols="4">
            <b-card>
              <b-card-actions v-if="aggregations.issn && aggregations.issn.buckets.length > 1" bg-variant="light" title="ISSN" action-collapse>
                <span v-for="(bucket, index) in aggregations.issn.buckets" :key="index">
                  <b-row>
                    <b-col cols="9">
                      <a class="text-primary" @click="searchOn('searchOnIssn', bucket.key)"> {{ bucket.key }} - {{ publications[bucket.key] }} </a>  
                    </b-col>
                    <b-col cols="3"><nobr>({{ bucket.doc_count }})</nobr></b-col>
                  </b-row>
                </span>
              </b-card-actions>
              <b-card-actions v-if="aggregations.issue && aggregations.issue.buckets.length > 1" bg-variant="light" title="Issues" action-collapse>
                <span v-for="(bucket, index) in aggregations.issue.buckets" :key="index">
                  <b-row v-if="index < 10 || showMoreIssues">
                    <b-col cols="9">
                      <a class="text-primary" @click="searchOn('issue', bucket.key)"> {{ bucket.key }}</a>  
                    </b-col>
                    <b-col cols="3"><nobr>({{ bucket.doc_count }})</nobr></b-col>
                  </b-row>
                </span>
                <br>
                <span v-if="aggregations.issue.buckets.length >= 10">
                  <a v-if="showMoreIssues" class="text-primary" @click="showMoreIssues = null">(show less)</a>  
                  <a v-else class="text-primary" @click="showMoreIssues = true">(show more)</a> 
                </span>
              </b-card-actions>
              <b-card-actions v-if="aggregations.article_category && aggregations.article_category.buckets.length > 1" bg-variant="light" title="Categories" action-collapse>
                <span v-for="(bucket, index) in aggregations.article_category.buckets" :key="index">
                  <b-row v-if="index < 10 || showMoreCategories">
                    <b-col cols="9">
                      <a class="text-primary" @click="searchOn('article_category', bucket.key)"> {{ labelForCat(bucket.key) }}</a>  
                    </b-col>
                    <b-col cols="3"><nobr>({{ bucket.doc_count }})</nobr></b-col>
                  </b-row>
                  <br v-if="index < 10 || showMoreCategories">
                </span>
                <br>
                <span v-if="aggregations.article_category.buckets.length >= 10">
                  <a v-if="showMoreCategories" class="text-primary" @click="showMoreCategories = null">(show less)</a>  
                  <a v-else class="text-primary" @click="showMoreCategories = true">(show more)</a> 
                </span>
              </b-card-actions>
              <b-card-actions v-if="aggregations.pmc_subject && aggregations.pmc_subject.buckets.length > 1" bg-variant="light" title="Categories" action-collapse>
                <span v-for="(bucket, index) in aggregations.pmc_subject.buckets" :key="index">
                  <b-row v-if="index < 10 || showMorePMCCategories">
                    <b-col cols="9">
                      <a class="text-primary" @click="searchOn('pmc_subject', bucket.key)"> {{ bucket.key }}</a>  
                    </b-col>
                    <b-col cols="3"><nobr>({{ bucket.doc_count }})</nobr></b-col>
                  </b-row>
                  <br v-if="index < 10 || showMorePMCCategories">
                </span>
                <br>
                <span v-if="aggregations.pmc_subject.buckets.length >= 10">
                  <a v-if="showMorePMCCategories" class="text-primary" @click="showMorePMCCategories = null">(show less)</a>  
                  <a v-else class="text-primary" @click="showMorePMCCategories = true">(show more)</a> 
                </span>
              </b-card-actions>
              <b-card-actions v-if="aggregations.article_type && aggregations.article_type.buckets.length > 1" bg-variant="light" title="Article Type" action-collapse>
                <span v-for="(bucket, index) in aggregations.article_type.buckets" :key="index">
                  <b-row v-if="index < 10 || showMoreArticleTypes">
                    <b-col cols="9">
                      <a class="text-primary" @click="searchOn('article_type', bucket.key)"> {{ bucket.key }}</a>  
                    </b-col>
                    <b-col cols="3"><nobr>({{ bucket.doc_count }})</nobr></b-col>
                  </b-row>
                </span>
                <br>
                <span v-if="aggregations.article_type.buckets.length >= 10">
                  <a v-if="showMoreArticleTypes" class="text-primary" @click="showMoreArticleTypes = null">(show less)</a>  
                  <a v-else class="text-primary" @click="showMoreArticleTypes = true">(show more)</a> 
                </span> 
              </b-card-actions>
              <b-card-actions v-if="aggregations.volume && aggregations.volume.buckets.length > 1" bg-variant="light" title="Volumes" action-collapse>
                <span v-for="(bucket, index) in aggregations.volume.buckets" :key="index">
                  <b-row v-if="index < 10 || showMoreVolumes">
                    <b-col cols="9">
                      <a class="text-primary" @click="searchOn('volume', bucket.key)"> {{ bucket.key }}</a>  
                    </b-col>
                    <b-col cols="3"><nobr>({{ bucket.doc_count }})</nobr></b-col>
                  </b-row>
                </span>
                <br>
                <span v-if="aggregations.volume.buckets.length >= 10">
                  <a v-if="showMoreVolumes" class="text-primary" @click="showMoreVolumes = null">(show less)</a>  
                  <a v-else class="text-primary" @click="showMoreVolumes = true">(show more)</a> 
                </span>
              </b-card-actions>
            </b-card>
          </b-col>
          <b-col :cols="colsForResults">
            <div v-for="(item, index) in reportResults" :key="index">
              <b-card>
                <b-row>
                  <b-col cols="12">
                    <span v-if="item.title.length > 0"> 
                      <!-- eslint-disable-next-line vue/no-v-html -->
                      <h4 v-html="$sanitize(item.title)"></h4>
                    </span>
                    <span v-else>
                      (Article Title Missing)
                    </span>
                  </b-col>
                </b-row>
                <span v-if="item.highlightedText">
                  <br>
                  <b-row>
                    <b-col cols="2"><b>Found in Text</b></b-col>
                    <b-col cols="10">
                      <span> 
                        <!-- eslint-disable-next-line vue/no-v-html -->
                        <span v-html="$sanitize(item.highlightedText)"></span>
                      </span>
                    </b-col>
                  </b-row>
                  <br>
                </span>
                <span v-if="item.highlightedTableText">
                  <br>
                  <b-row>
                    <b-col cols="2"><b>Found in Tables</b></b-col>
                    <b-col cols="10">
                      <span> 
                        <!-- eslint-disable-next-line vue/no-v-html -->
                        <span v-html="$sanitize(item.highlightedTableText)"></span>
                      </span>
                    </b-col>
                  </b-row>
                  <br>
                </span>
                <span v-if="item.highlightedFigures">
                  <br>
                  <b-row>
                    <b-col cols="2"><b>Found in Figure</b></b-col>
                    <b-col cols="10">
                      <span v-for="(fig, fi) in item.highlightedFigures" :key="fi">
                        <b>{{ fig.title }}</b><br>
                        <b>{{ fig.imageName }}</b><br>
                      </span>
                    </b-col>
                  </b-row>
                  <br>
                </span>
                <span v-if="item.highlightedSupplMat">
                  <br>
                  <b-row>
                    <b-col cols="2"><b>Found in Suppl. Mat.</b></b-col>
                    <b-col cols="10">
                      <span v-for="(sup, si) in item.highlightedSupplMat" :key="si">
                        <b>{{ sup.fileName }}</b><br>
                      </span>
                    </b-col>
                  </b-row>
                  <br>
                </span>
                <b-row>
                  <b-col cols="3">
                    <span v-if="searchParams.volume"> 
                      <b>Volume</b> {{ item.volume }} /
                      <span v-if="searchParams.issue">  <b>Issue</b> {{ item.issue }} </span>
                      <a v-else class="text-primary" @click="searchOn('issue', item.issue)"> Issue {{ item.issue }} </a>  
                    </span>
                    <span v-else>
                      <a  class="text-primary" @click="searchOn('volume', item.volume)"> Volume {{ item.volume }}</a>  /
                      <b>Issue</b>{{ item.issue }} 
                    </span>
                  </b-col>
                  <b-col cols="4">{{ item.doi }}</b-col>
                  <b-col cols="2">               
                    <router-link  :to="articleAdminRouterName(item)">Admin View</router-link>
                  </b-col>
                  <b-col cols="2">               
                    <router-link  :to="articleWebRouterName(item)" target="_blank"> Web View</router-link>
                  </b-col>
                </b-row>
                <br>
                <b-row>
                  <b-col v-if="item.epub" cols="3"><b>EPub</b> {{ item.epub.split('T')[0] }}</b-col>
                  <b-col v-if="item.ppub" cols="3"><b>PPub</b> {{ item.ppub.split('T')[0] }}</b-col>
                  <b-col cols="5">
                    <span v-if="searchParams.article_type != null"> <b>Article Type (jats):</b> {{ item.article_type }}</span>
                    <span v-else> <a class="text-primary" @click="searchOn('article_type', item.article_type)"> Article Type (jats) {{ item.article_type }}</a> </span>
                  </b-col>
                </b-row>
                <br/>
                <b-row> 
                  <b-col vols="2"><b>Authors</b></b-col>
                  <b-col v-if="searchParams.author && searchParams.author.length > 0" cols="10">
                    <span v-for="(auth, authIndex) in item.author" :key="authIndex"> 
                      {{ auth }} ,
                    </span>
                  </b-col>
                  <b-col v-else cols="10">
                    <span v-for="(auth, authIndex) in item.author" :key="authIndex"> 
                      <a class="text-primary" @click="searchOn('author', auth)"> {{ auth }} </a> ,   
                    </span>
                  </b-col>
                </b-row>
                <br/>
                <b-row> 
                  <b-col cols="3"><b>More</b>
                    <b-button
                      size="sm"
                      variant="flat-primary"
                      @click="expandMore = !expandMore"
                    >
                      <feather-icon v-if="expandMore" icon="ChevronDownIcon" size="12"/>
                      <feather-icon v-else icon="ChevronUpIcon" size="12"/>
                    </b-button>
                  </b-col>
                </b-row>
                <span v-if="expandMore && item.affiliations">
                  <br>
                  <b-row>
                    <b-col  cols="2"><b>Affiliations</b></b-col>
                    <b-col cols="10">
                      <span v-for="(aff, aff_index) in item.affiliations" :key="aff_index">
                        <a class="text-primary" @click="searchOn('affiliation', aff)"> {{ aff }}</a> 
                        <span v-if="aff_index < item.affiliations.length - 1"> / </span>
                      </span>
                    </b-col>
                  </b-row>
                </span>
                <span v-if="expandMore && item.funding">
                  <br>
                  <b-row>
                    <b-col  cols="2"><b>Funders</b></b-col>
                    <b-col cols="10">
                      <span v-for="(funder, fun_index) in item.funding" :key="fun_index">
                        <a class="text-primary" @click="searchOn('funding', funder)"> {{ funder }}</a>  
                        <span v-if="fun_index < item.funding.length - 1"> / </span>
                      </span>
                    </b-col>
                  </b-row>
                </span>
                <span v-if="expandMore && item.license">
                  <br>
                  <b-row>
                    <b-col  cols="2"><b>License</b></b-col>
                    <b-col cols="10">{{  item.license }} </b-col>
                  </b-row>
                </span>
                <br/>
                <b-row v-if="expandMore"> 
                  <b-col cols="3"><b>Categories </b>
                  </b-col>
                  <span v-if="FORMAT == 'literatum'" >
                    <b-col v-if="searchParams.articleCategories.length > 0" cols="9">
                      <span v-for="(cat, catIndex) in item.article_category" :key="catIndex"> 
                        {{ labelForCat(cat) }} ,
                      </span>
                    </b-col>
                    <b-col v-else cols="10">
                      <span v-for="(cat, catIndex) in item.article_category" :key="catIndex"> 
                        <a class="text-primary" @click="searchOn('article_category', cat)"> {{ labelForCat(cat) }} </a> ,   
                      </span>
                    </b-col>
                  </span>
                  <span v-if="FORMAT == 'PMC'">
                    <b-col cols="9">
                      <span v-for="(cat, catIndex) in  pmcResultCategoryDisplay(item) " :key="catIndex"> 
                        <span v-if="cat.length > 100">{{ cat }}</span>
                        <nobr v-else>{{ cat }}</nobr>
                        <br><br>
                      </span>
                    </b-col>
                  </span>
                </b-row>
              </b-card>
              <br>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col v-if="aggregations" cols="4"></b-col>
          <b-col :cols="colsForResults">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalResults.value"
              :per-page="resultsPerPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              align="right"
            />
          </b-col>
        </b-row>
      </div>
    </b-card>
    <!-- MODALS HERE-->
    <!-- modal Taxonomy Selection-->
    <b-modal 
      v-if="topicData"
      id="taxonomy-modal-id" 
      scrollable
      title="Article Category Selection" 
      size="lg"
    >
      <b-tabs>
        <b-tab title="Type">
          <b-card> 
            <div  v-for="(item, index) in activityTypeData"  :key="index">
              <b-row>
                <b-col cols="12">
                  <b-form-checkbox
                    v-model="taxonomySelections"
                    :value="item.tagUri"
                    plain
                  >
                    {{ item.tagUri }} : {{ tagUriLabels[item.tagUri] }}
                  </b-form-checkbox>
                </b-col>
              </b-row>
            </div>
          </b-card>
        </b-tab>
        <b-tab title="Topic">
          <b-card>
            <div  v-for="(item, index) in topicData"  :key="index">
              <b-row>
                <b-col v-if="item.parentTag" cols="1"/>
                <b-col cols="11">
                  <b-form-checkbox
                    v-model="taxonomySelections"
                    :value="item.tagUri"
                    plain
                  >
                    {{ item.tagUri }} : {{ tagUriLabels[item.tagUri] }}
                  </b-form-checkbox>
                </b-col>
              </b-row>
            </div>
          </b-card>
        </b-tab>
        <b-tab title="Badge">
          <b-card>
            <div  v-for="(item, index) in badgeData"  :key="index">
              <b-row>
                <b-col cols="12">
                  <b-form-checkbox
                    v-model="taxonomySelections"
                    :value="item.tagUri"
                    plain
                  >
                    {{ item.tagUri }} : {{ tagUriLabels[item.tagUri] }}
                  </b-form-checkbox>
                </b-col>
              </b-row>
            </div>
          </b-card>
        </b-tab>
        <b-tab title="Special Feature">
          <b-card>
            <div  v-for="(item, index) in specialFeatureData"  :key="index">
              <b-row>
                <b-col cols="12">
                  <b-form-checkbox
                    v-model="taxonomySelections"
                    :value="item.tagUri"
                    plain
                  >
                    {{ item.tagUri }} : {{ tagUriLabels[item.tagUri] }}
                  </b-form-checkbox>
                </b-col>
              </b-row>
            </div>
          </b-card>
        </b-tab>
        <b-tab title="Colloquia">
          <b-card>
            <div  v-for="(item, index) in colloquiaData"  :key="index">
              <b-row>
                <b-col cols="12">
                  <b-form-checkbox
                    v-model="taxonomySelections"
                    :value="item.tagUri"
                    plain
                  >
                    {{ item.tagUri }} : {{ tagUriLabels[item.tagUri] }}
                  </b-form-checkbox>
                </b-col>
              </b-row>
            </div>
          </b-card>
        </b-tab>
      </b-tabs>
      <template #modal-footer>
        <div class="w-100">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            size="sm"
            class="float-left"
            @click.stop="closeTaxonomyModal()"
          >
            Cancel
          </b-button>
          <b-button 
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="primary" 
            size="sm"
            class="float-right"
            @click.stop="updateCategoryFilter()"
          >
            Update Filter
          </b-button>
        </div>
      </template>
    </b-modal>
  </section>
</template> 
 
<script>

import publicationApi from '@/dl_pubmill/apis/publication'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import { $themeConfig } from '@themeConfig'

import {
  BCard, BRow, BCol, BButton, BFormCheckbox, BFormInput, BFormTags,  BTabs, BTab, BFormSelect, BPagination,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {

  components: {
    BCard,
    BCardActions,
    BRow,
    BCol,
    BButton,
    BFormCheckbox,
    BFormInput,
    BFormSelect,
    BFormTags,
    BTabs, 
    BTab,
    BPagination,
  },
  directives: {
    Ripple,
  },
  props: {
    publications: {
      type: Object,
      default: null
    },
    articlesFilter: {
      type: Object,
      default: null
    },
  },
  data () {
    return {
      FORMAT: null,
      doi: null,
      searchParams: {},
      taxonomySelections: [],
      tagUriLabels: null,
      tagUriDict: null,
      topicData: null,      
      badgeData: null,
      specialFeatureData: null,      
      colloquiaData: null,     
      activityTypeData: null,
      totalResults: null,
      currentPage: null,
      resultsPerPage: null,
      exportResults: null,
      aggregations: null,
      showMoreArticleTypes: null,
      showMoreCategories: null,
      showMorePMCCategories: null,
      showMoreVolumes: null,
      showMoreIssues: null,
      reportResults: null,
      reportResultsError: null,
      expandMore: null,
      showAdvanced: null,
      finishedLoadingTaxonomy: null

    }
  },
  computed: {

    pmcSearchCategoryDisplay () {
      if (!this.searchParams.pmcArticleCategories) {
        return ''
      }
      if (!this.searchParams.pmcArticleCategories.includes(':_:')) {
        return this.searchParams.pmcArticleCategories
      }

      const nodes = this.searchParams.pmcArticleCategories.split(':_:')
      let returnResults = ''
      for (let i = 0; i < nodes.length; i++) {
        let pref = ''
        if (i > 0) {
          pref = ' / '
        }
        returnResults = returnResults.concat(pref, nodes[i])
      }
      return returnResults
    },

    colsForResults () {
      if (this.aggregations) {
        return '8'
      } 
      return '12'  
    },
    licenses () {
      return [
        { value: null, text: '' },
        { value: 'creativecommons.org_licenses_by-nc-nd_4.0_', text: 'Creative Commons (CC BY-NC-ND)' },
        { value: 'creativecommons.org_licenses_by_4.0_', text: 'Creative Commons (CC BY)' },
        { value: 'www.pnas.org_site_aboutpnas_licenses.xhtml', text: 'PNAS license' },
      ]
    },
    articleTypes () {
      return [
        { value: null, text: '' },
        { value: 'research-article', text: 'research-article' },
        { value: 'addendum', text: 'addendum' },
        { value: 'article-commentary', text: 'article-commentary' },
        { value: 'letter', text: 'letter' },
        { value: 'reply', text: 'reply' },
        { value: 'correction', text: 'correction' },
        { value: 'retraction', text: 'retraction' },
        { value: 'editorial', text: 'editorial' },
        { value: 'in-brief', text: 'in-brief' },
        { value: 'book-review', text: 'book-review' },
        { value: 'review-article', text: 'review-article' },
        { value: 'brief-report', text: 'brief-report' },
        { value: 'index', text: 'index' },
        { value: 'introduction', text: 'introduction' },
        { value: 'meeting-report', text: 'meeting-report' },
        { value: 'other', text: 'other' },
      ]
    },
    resultsPerPageOptions () {
      return [25, 50, 100, 500]
    },
    fullDoiEntered () {
      return this.doi && this.doi.trim().length > 15 && this.doi.includes('/')
    },
  },
  watch: {
    currentPage: {
      //watch on route changes it not only needed when same .vue is enteredfrom multiple nav points 
      handler (newVal, oldVal) {
        console.log('currentPage:', this.currentPage, 'newVal:', newVal, 'oldVal:', oldVal)
        if (oldVal != null) {
          this.searchQueryRequest()
        }
      }
    },
    resultsPerPage: {
      //watch on route changes it not only needed when same .vue is enteredfrom multiple nav points 
      handler (newVal, oldVal) {
        console.log('resultsPerPage:', this.resultsPerPage, 'newVal:', newVal, 'oldVal:', oldVal)
        if (oldVal != null) {
          this.currentPage = 1 //looks like this does not trigger the watch .. so calling query here
          this.searchQueryRequest()
        }
      }
    }   
  },
  mounted () { 
    console.log('4444444444 searchArticles mounted')
    this.FORMAT =  $themeConfig.app.envVars.FORMAT
    if (this.FORMAT === 'literatum') {
      this.getTaxonomy() 
    }
    this.initSearchParams()
  },
  methods: {
    labelForCat (value) {
      let returnVal = this.tagUriLabels[value]
      if (returnVal == null) {
        returnVal = 'Invalid Category: '.concat(value)
      }
      return returnVal
    },
    pmcResultCategoryDisplay (searchResult) {
      
      if (!searchResult.pmc_subject) {
        return []
      }
      const returnResults = []
      for (let i = 0; i < searchResult.pmc_subject.length; i++) {
        const cat = searchResult.pmc_subject[i]
        if (cat.level6) {
          returnResults.push(cat.level6.replaceAll(':_:', ' / '))
        } else if (cat.level5) {
          returnResults.push(cat.level5.replaceAll(':_:', ' / '))
        } else if (cat.level4) {
          returnResults.push(cat.level4.replaceAll(':_:', ' / '))
        } else if (cat.leve3) {
          returnResults.push(cat.level3.replaceAll(':_:', ' / '))
        } else if (cat.level2) {
          returnResults.push(cat.level2.replaceAll(':_:', ' / '))
        } else  {
          returnResults.push(cat.level1)
        }
      }
      return returnResults
    },
    initSearchParams () {
      this.searchParams =  {
        inTitleOrText: true,
        articleCategories: [],
        pmcArticleCategories: null,
        //searchOnIssn: this.$route.params.issn,
      }
      if (this.articlesFilter && this.articlesFilter.type === 'author') {
        //console.log('aaaaa', this.articlesFilter)
        this.searchParams.author = [this.articlesFilter.value]
        this.articlesFilter.type = null
      }
      if (this.articlesFilter && this.articlesFilter.type === 'affiliation') {
        //console.log('aaaaa', this.articlesFilter)
        this.searchParams.affiliation = this.articlesFilter.value
        this.articlesFilter.type = null
      }
      if (this.articlesFilter && this.articlesFilter.type === 'funding') {
        //console.log('aaaaa', this.articlesFilter)
        this.searchParams.funding = this.articlesFilter.value
        this.articlesFilter.type = null
      }
      this.doi = null
      this.resultsPerPage = 25
      this.currentPage = 1
      this.searchQueryRequest()
    },
    initPmcArticleCategoriesSearch () {
      this.searchParams.pmcArticleCategories = null
      this.searchQueryRequest()
    },
    searchOn(field, value) {
      this.doi = null 

      if (field === 'searchOnIssn') {
        this.searchParams.searchOnIssn = value
        this.searchQueryRequest()
        if (this.$route.params.issn !== value || this.$route.params.tab !== 0) {
          console.log('changing routing', value, this.$route.params.tab)
          this.$router.push({ name: 'search', params: { issn: value, tab: 0 } })
        }
        return
      }
    
      if (field === 'article_type') {
        this.searchParams.article_type = value
      } else if (field === 'article_category') {
        this.searchParams.articleCategories = [{ tagCode: value, tagLabel: this.labelForCat(value) }]
      } else if (field === 'pmc_subject') {
        if (this.searchParams.pmcArticleCategories) {
          this.searchParams.pmcArticleCategories = this.searchParams.pmcArticleCategories.concat(':_:', value)
        } else {
          this.searchParams.pmcArticleCategories = value
        }
        
      } else if (field === 'volume') {
        this.searchParams.volume = value
      } else if (field === 'issue') {
        this.searchParams.issue = value
      } else if (field === 'author') {
        this.searchParams.author = [value]
      } else if (field === 'affiliation') {
        this.searchParams.affiliation = value
      } else if (field === 'funding') {
        this.searchParams.funding = value
      } 
      this.searchQueryRequest()
    },
    articleAdminRouterName (reportResult) {
      const nodes = reportResult.doi.split('/')
      let fn = reportResult.doi.split(nodes[0].concat('/'))[1]
      if (fn.includes('/')) fn = fn.replace('/', '.')
      return {
        name: 'article-publication-detail-admin',
        params: {
          issn: reportResult.issn,
          vol: reportResult.volume,
          issue: reportResult.issue,
          file_name: fn,
        }
      }
    },
    articleWebRouterName (reportResult) {
      const nodes = reportResult.doi.split('/')
      let fn = reportResult.doi.split(nodes[0].concat('/'))[1]
      if (fn.includes('/')) fn = fn.replace('/', '.')
      return {
        name: 'article-web-publication-doi',
        params: {
          issn: reportResult.issn,
          doi_prefix: reportResult.doi.split('/')[0],
          doi_suffix: fn,
        }
      }
    }, 
    promptToSelectNewTaxonomy () {
      this.taxonomySelections = []
      for (let i = 0; i < this.searchParams.articleCategories.length; i++) {
        this.taxonomySelections.push(this.searchParams.articleCategories[i].tagCode)
      }
      this.$bvModal.show('taxonomy-modal-id')
    },
    buildTaxUriDataOn (taxIn) {
      const parentChildRelationships = {}
      for (let i = 0; i < taxIn.tagUris.length; i++) {
        const tagUri =  taxIn.tagUris[i]
        if (tagUri.parentTag) {
          if (!parentChildRelationships[tagUri.parentTag]) {
            parentChildRelationships[tagUri.parentTag] = []
          }
          parentChildRelationships[tagUri.parentTag].push(tagUri)
        }
      }
      //console.log('parentChildRelationships', parentChildRelationships)
      const taxOut = []
      const keys = Object.keys(parentChildRelationships)
      //console.log('keys', keys)
      keys.sort()
      for (let i = 0; i < keys.length; i++) {
        let toRec = { taxUri: taxIn.taxUri, tagUri: keys[i] }
        taxOut.push(toRec)
        //console.log('parent', toRec.tagUri)
        const tagUris = parentChildRelationships[keys[i]]
        for (let j = 0; j < tagUris.length; j++) {
          toRec = { taxUri: taxIn.taxUri, tagUri: tagUris[j].tagUri, parentTag: tagUris[j].parentTag }
          //console.log('child', toRec.tagUri,  toRec.parentTag)
          taxOut.push(toRec)
        } 
      }
      for (let i = 0; i < taxIn.tagUris.length; i++) {
        const tagUri = taxIn.tagUris[i]
        //console.log('dddddddddddd', tagUri)
        //skip root tags because they were added above
        if (!tagUri.parentTag && parentChildRelationships[tagUri.tagUri]) {
          //console.log('skipping ', tagUri)
          continue
        }
        //skip tags with parent because they were added above
        if (tagUri.parentTag) {
          //console.log('skipping ', tagUri)
          continue
        }
        const toRec = { taxUri: taxIn.taxUri, tagUri: tagUri.tagUri, parentTag: tagUri.parentTag }
        taxOut.push(toRec)
      }
      //console.log('ttttxxx out', taxOut)
      return taxOut 
    },
    buildNewTaxonomyFromSelections () {

      //console.log('ttttttttttttt', this.tagUriDict, this.tagUriDict)
      const parentsOfChildrenSelected = new Set()
      for (let i = 0; i <  this.taxonomySelections.length; i++) {
        const rec = this.tagUriDict[this.taxonomySelections[i]]
        if (rec.parentTag) {
          parentsOfChildrenSelected.add(rec.parentTag)
        }
      }
      //console.log('parentsOfChildrenSelected', parentsOfChildrenSelected)
      const newTaxonomy = []
      for (let i = 0; i <  this.taxonomySelections.length; i++) {
        const rec = this.tagUriDict[this.taxonomySelections[i]]
        //don't add parent selections when children are selected
        if (!rec.parentTag && parentsOfChildrenSelected.has(rec.tagUri)) {
          //console.log('skipping', rec)
          continue
        }
        newTaxonomy.push({ tagCode: rec.tagUri, tagLabel: this.tagUriLabels[rec.tagUri], tagUri: rec.taxUri })
      }
             
      //console.log('newTaxonomy', newTaxonomy)
      this.searchParams.articleCategories =  newTaxonomy

    },
    updateCategoryFilter () {
      this.buildNewTaxonomyFromSelections()
      this.closeTaxonomyModal() 
    },
    closeTaxonomyModal () {
      this.$bvModal.hide('taxonomy-modal-id')
    },
    getTaxonomy () {
      this.activityTypeData = null
      this.topicData = null
      this.badgeData = null
      this.specialFeatureData = null
      this.colloquiaData = null
      this.finishedLoadingTaxonomy = null
      publicationApi.getTaxonomy(this.$route.params.issn, 'n', this, null)
    },
    backFromGetTaxonomy (serverData) {
      if (serverData.error) {
        console.log(serverData.error)
        return
      } 
      console.log('backFromTaxonomy', serverData)
      if (!serverData.taxUris) {
        this.finishedLoadingTaxonomy = 'y'
        return
      }
      this.tagUriLabels = serverData.tagUriLabels

      this.activityTypeData = this.buildTaxUriDataOn(serverData.taxUris['Article Types'])
      this.topicData = this.buildTaxUriDataOn(serverData.taxUris.Topics)
      this.badgeData = this.buildTaxUriDataOn(serverData.taxUris.Badges)
      this.specialFeatureData = this.buildTaxUriDataOn(serverData.taxUris['Special Feature'])
      this.colloquiaData = this.buildTaxUriDataOn(serverData.taxUris.Colloquia)

      const tempDict = {} 
      let data = this.activityTypeData
      //console.log('aaaaa', data)
      for (let i = 0; i < data.length; i++) {
        const key = data[i].tagUri
        tempDict[key] = data[i]
      }
      data = this.topicData
      for (let i = 0; i < data.length; i++) {
        const key = data[i].tagUri
        //console.log('00000', data[i].tagUri)
        tempDict[key] = data[i]
        //console.log('111111', key, tempDict[key])
      }
      data = this.badgeData
      for (let i = 0; i < data.length; i++) {
        const key = data[i].tagUri
        tempDict[key] = data[i]
      }
      data = this.specialFeatureData
      for (let i = 0; i < data.length; i++) {
        const key = data[i].tagUri
        tempDict[key] = data[i]
      }
      data = this.colloquiaData
      for (let i = 0; i < data.length; i++) {
        const key = data[i].tagUri
        tempDict[key] = data[i]
      }
      this.tagUriDict = tempDict
  
      this.finishedLoadingTaxonomy = 'y'

    }, 
    exportSearcnResults () {
      this.exportResults = 'y'
      this.performSearch()
    },
    performSearch () {
      if (this.fullDoiEntered) {
        this.searchByDOI()
      } else {
        this.searchQueryRequest()
      }
    },
    processSearchResults (serverData) {
      if (serverData.error) {
        console.log(serverData.error)
        this.reportResultsError = serverData.error
      } else {
        this.totalResults =  serverData.hits.total
        const pageResults =  serverData.hits.hits
        this.aggregations = serverData.aggregations

        console.log('reportResults', serverData)

        this.reportResults = []
        for (let i = 0; i < pageResults.length; i++) {
          const source = pageResults[i].source
          const highlight = pageResults[i].highlight
          if (highlight) {
            if (highlight.text) {
              source.highlightedText = highlight.text
            }
            if (highlight.tableText) {
              source.highlightedTableText = highlight.tableText
            }
            if (highlight.figures) {
              source.highlightedFigures = highlight.figures
            }
            if (highlight.supplementaryText) {
              source.highlightedSupplMat = highlight.supplementaryText
            }
          }

          this.reportResults.push(source)

        }

      }
    },
    searchByDOI () {
      this.reportResults = null
      this.reportResultsError = null
 
      publicationApi.searchByDOI(this.doi.trim(), this.$route.params.issn, this, null)
    },
    backFromSearchByDOI (serverData) {
      this.processSearchResults(serverData)
    },
    searchQueryRequest () {
      this.reportResults = null
      this.reportResultsError = null
 
      const requestObj = {
        issn: this.$route.params.issn,
      }

      requestObj.returnRows =  this.resultsPerPage
      if (this.currentPage > 1) {
        requestObj.startingRow =  this.resultsPerPage * (this.currentPage - 1)
      }
      if (this.searchParams.searchOnIssn && this.searchParams.searchOnIssn.trim().length > 0) {
        requestObj.searchOnIssn =   this.searchParams.searchOnIssn.trim()
      } 
      if (this.searchParams.volume && this.searchParams.volume.trim().length > 0) {
        requestObj.volume =   this.searchParams.volume.trim()
      } 
      if (this.searchParams.issue && this.searchParams.issue.trim().length > 0) {
        requestObj.issue =   this.searchParams.issue.trim()
      } 
      if (this.searchParams.term && this.searchParams.term.trim().length > 0) {
        requestObj.search_text =   this.searchParams.term.trim()
      } 
      if (this.searchParams.inTitle) {
        requestObj.in_title =  'y'
      }
      if (this.searchParams.inTitleOrText) {
        requestObj.in_title_or_text =  'y'
      }
      if (this.searchParams.inFigures) {
        requestObj.in_figures =  'y'
      }
      if (this.searchParams.inTables) {
        requestObj.in_tables =  'y'
      }
      if (this.searchParams.inSupplMat) {
        console.log('tttttt', requestObj.search_text)
        requestObj.in_suppl_mat =  'y'
      }

      if (this.searchParams.hasTables) {
        requestObj.hasTables =  'y'
      }
      if (this.searchParams.hasMathML) {
        requestObj.hasMathML =  'y'
      }
      if (this.searchParams.hasLatex) {
        requestObj.hasLatex =  'y'
      }
      if (!this.searchParams.inTitle && !this.searchParams.inTitleOrText && !this.searchParams.inFigures &&  !this.searchParams.inTables && !this.searchParams.inSupplMat) {
        requestObj.in_title_or_text =  'y'
        this.searchParams.inTitleOrText = true
      }

      const cats = []
      for (let i = 0; i < this.searchParams.articleCategories.length; i++) {
        cats.push(this.searchParams.articleCategories[i].tagCode)
      }
      if (cats.length > 0) {
        requestObj.article_category = cats
      }
      if (this.searchParams.pmcArticleCategories) {
        //console.log('ffffff', this.searchParams.pmcArticleCategories)
        requestObj.article_category = [this.searchParams.pmcArticleCategories]
      }
  
      if (this.searchParams.article_type) {
        requestObj.article_type =  this.searchParams.article_type
      }
      if (this.searchParams.from_year && this.searchParams.from_year.trim().length === 4) {
        if (this.searchParams.epub) {
          requestObj.from_epub =  this.searchParams.from_year.trim().concat('-01-01T00:00:00Z')
        } else {
          requestObj.from_ppub =  this.searchParams.from_year.trim().concat('-01-01T00:00:00Z')
        }
      }
      if (this.searchParams.to_year && this.searchParams.to_year.trim().length === 4) {
        if (this.searchParams.epub) {
          requestObj.to_epub =  this.searchParams.to_year.trim().concat('-12-31T00:00:00Z')
        } else {
          requestObj.to_ppub =  this.searchParams.to_year.trim().concat('-12-31T00:00:00Z')
        }
      }

      //license: "creativecommons.org_licenses_by-nc-nd_4.0_",
      //license: "nc-nd",
      //"license: "creativecommons.org_licenses_by_4.0_",
      //"license: "www.pnas.org_site_aboutpnas_licenses.xhtml",
      if (this.searchParams.license && this.searchParams.license.trim().length > 0) {
        requestObj.license =   this.searchParams.license.trim()
      }

      //requestObj.funding = ['Wellcome'] 
      if (this.searchParams.funding && this.searchParams.funding.trim().length > 0) {
        requestObj.funding =   this.searchParams.funding.trim()
      }
      if (this.searchParams.affiliation && this.searchParams.affiliation.trim().length > 0) {
        requestObj.affiliations =   this.searchParams.affiliation.trim()
      }

      if (this.searchParams.author && this.searchParams.author.length > 0) {
        if (this.searchParams.correspOnly) {
          requestObj.authorCorresp =   this.searchParams.author
        } else {
          requestObj.author =   this.searchParams.author
        }
      }
      //'author: ["Xu, Huadong","Kang, Xinjiang"],

      //console.log('ssssss searchParams', this.searchParams)
      //console.log('ssssss requestObj', requestObj)
      requestObj.action =  'search_query_articles_request'

      if (this.exportResults === 'y') {
        requestObj.searchOnIssn =  requestObj.issn
        requestObj.returnRows =  10000
        requestObj.startingRow =  0
        requestObj.exportQuery =  'y' 
      }
      publicationApi.searchQueryRequest(requestObj, this, null)
    },
    backFromSearchQueryRequest (serverData) {
      if (this.exportResults === 'y') {
        this.exportResults = null
        this.$router.push({ name: 'reports', params: { issn: this.$route.params.issn } })
        return
      }
      this.processSearchResults(serverData)
    }
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
